import React, { useEffect, useState, useContext } from 'react';
import {
    ModalBody,
    ModalFooter,
    Button,
    FormControl,
    FormLabel,
    Flex,
    Checkbox,
    Text
} from '@chakra-ui/react'
import apiConfig from '../../config/apiconfig';
import axios from 'axios';
import { ErrorNotifierContext } from '../../providers/errorNotifier';


export default function FoodRecommenderStockItemForm({ callback, customOnClose }) {
    const [stockItems, setStockItems] = useState([]);
    const [withPicture, setWithPicture] = useState(false);
    const [selectedStockItems, setSelectedStockItems] = useState([]);

    const { notify } = useContext(ErrorNotifierContext);

    useEffect(() => {
        if (!!localStorage.getItem('householdId')) {
            axios.get(apiConfig.webApi + 'stockitems?householdId=' + localStorage.getItem('householdId'))
                .then((response) => {
                    setStockItems(response.data.list);
                })
                .catch((error) => {
                    notify(error, 'Sikertelen lekérdezés');
                });
        }
    //eslint-disable-next-line
    }, []);

    const checkBoxClicked = (checked, id) => {
        if (checked) {
            setSelectedStockItems([...selectedStockItems, id]);
        }
        else {
            setSelectedStockItems(selectedStockItems.filter(item => item !== id));
        }
    };

    const allSelected = () => {
        setSelectedStockItems(stockItems.map(item => item.id));
    };

    const noneSelected = () => {
        setSelectedStockItems([]);
    };

    return (
        <>
            <form onSubmit={event => callback(event, selectedStockItems, withPicture)}>
                <ModalBody pb={6}>
                    <FormControl>
                        <FormLabel>Felhasználni kívánt hozzávalók</FormLabel>
                        <Flex justifyContent='flex-end' my='4'>
                            <Checkbox colorScheme='green' isChecked={withPicture} onChange={(event) => setWithPicture(event.target.checked)}>Képet is generáljon</Checkbox>
                        </Flex>
                        <Flex justifyContent='flex-end'>
                            <Button onClick={allSelected} fontSize='small' mx='2'>Összes</Button>
                            <Button onClick={noneSelected} fontSize='small' mx='2'>Egyik sem</Button>
                        </Flex>
                        {stockItems.map(item => <>
                            <Flex bg='#ed7d31' color='whitesmoke' p={4} gap="2" borderRadius={10} flexDir="row" alignItems="center" justifyContent='space-between' key={item.id} w='auto' maxW='400px' my="4">
                                <Checkbox colorScheme='green' isChecked={selectedStockItems.includes(item.id)} onChange={(event) => checkBoxClicked(event.target.checked, item.id)}>{ item.productName }</Checkbox>
                                <Text mr='4' >{ item.quantity } db</Text>
                            </Flex>
                        </>)}
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={customOnClose}>Mégse</Button>
                    <Button type="submit" bg='#548235' color='whitesmoke' ml={3}>Nézzük a receptet</Button>
                </ModalFooter>
            </form>
        </>
    )
}
