import React from 'react';
import {
    Flex,
    Text,
    HStack
} from '@chakra-ui/react'
import BaseForm from '../../components/basePage/baseForm';
import BaseDeleteModal from '../../components/basePage/baseDeleteModal'

export default function CostListItem({item, entityName, formTitle, updateData, form, foreignKey, bic}){
    return (
        <Flex bg='#548235' color='whitesmoke' p={4} gap="2" borderRadius={10} flexDir="row" alignItems="center" justifyContent='space-between' key={item.id} w='full' maxW='550px'>
            <Flex w="full" justifyContent='space-between'>
                <Flex w="full" justifyContent='space-between'>
                    <Text mr='4' fontSize='medium'>{ item.name }</Text>
                    <Text mr='4' fontSize='medium'>{ item.amount } Ft</Text>
                </Flex>
                <Text w='100px'fontSize='medium'>{ item.costTypeName }</Text>
            </Flex>
            {!bic && <HStack>
                <BaseForm isEdit={true} entity={item} entityName={entityName} title={formTitle} callback={updateData} form={form} foreignKey={foreignKey}/>
                <BaseDeleteModal id={item.id} entityName={entityName} callback={updateData}/>
            </HStack>}
        </Flex>
    );
};
