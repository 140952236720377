import React from 'react';
import BaseListPage from '../../components/basePage/baseListPage';
import HouseholdListItem from './householdListItem';
import HouseholdForm from './householdForm';

const HouseholdsPage = ({ householdId }) => {
    return <BaseListPage
        entityName='households'
        title='Háztartásaim'
        formTitle='Háztartás'
        listItem={HouseholdListItem}
        form={HouseholdForm}
        foreignKeyName='householdId'
        foreignKey={householdId}
        bg='#548235'
        sortStrategy={(a, b) => (a.name > b.name) ? 1 : ((a.name < b.name) ? -1 : 0)}
        reload={true} />;
}

export default HouseholdsPage;
