import React, { useState, useEffect, useContext } from 'react';
import {
    VStack,
    Center,
    Heading,
    useToast,
    Flex,
    HStack,
} from '@chakra-ui/react'
import axios from 'axios';
import apiConfig from '../../config/apiconfig';
import BaseForm from './baseForm';
import NoHouseholdPage from '../navigation/noHouseholdPage';
import { useNavigate } from 'react-router-dom';
import { ErrorNotifierContext } from '../../providers/errorNotifier';
import * as signalR from '@microsoft/signalr';

const BaseListPage = ({entityName, title, listItem, formTitle, form, mt, bg, foreignKeyName, foreignKey, titleColor, sortStrategy, reload, mildReload, additionalUrl, buttonsInvisible, actionButtons, w}) => {
    const [data, setData] = useState();
    const [canAdd, setCanAdd] = useState();
    
    const toast = useToast();
    const navigate = useNavigate();
    const { notify } = useContext(ErrorNotifierContext);

    const doFetch = () => {
        var url = apiConfig.webApi + entityName;
        if (!foreignKeyName) {
            foreignKeyName = 'householdId';
        }
        if (!!foreignKey) {
            url = url + '?' + foreignKeyName + '=' + foreignKey;
        }
        if (!!additionalUrl) {
            url = url + additionalUrl;
        }
        if (!!localStorage.getItem('householdId') || entityName === 'households') {
            axios.get(url)
                .then((response) => {
                    setData(!!sortStrategy ? response.data.list.toSorted(sortStrategy) : response.data.list);
                    setCanAdd(response.data.canAdd);
                })
                .catch((error) => {
                    notify(error, 'Sikertelen lekérdezés');
                });
        }
    };

    const updateData = (id, newData) => {
        if (mildReload) {
            doFetch();
        }
        else {
            const oldData = !!data && data.length > 0 ? data.filter(item => item.id !== id) : [];
            var dataToSort;
            if (!!newData) {
                dataToSort = [newData, ...oldData];
            }
            else {
                dataToSort = oldData;
            }
    
            if (!!sortStrategy) {
                setData(dataToSort.toSorted(sortStrategy));
            }
            else {
                setData(dataToSort);
            }
            if (!!reload) {
                setTimeout(() => {
                    if (!dataToSort || dataToSort.length === 0) {
                        localStorage.removeItem('householdId');
                    }
                    window.location.reload();
                    doFetch();
                }, 300);
            }
        }
    };

    useEffect(() => {
        doFetch();
    // eslint-disable-next-line
    }, [entityName]);

    useEffect(() => {
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(apiConfig.signalRHub, { withCredentials: true })
            .build();
    
        connection.on("RecordModified", (notification) => {
            if (notification.entityName === entityName && (!foreignKey || notification.foreignKey === foreignKey)) {
                doFetch();
            }
            if (notification.entityName === "costs" && (!foreignKey || notification.foreignKey === foreignKey)) {
                navigate("/monthlysummary");
            }
        });
    
        connection.start().catch(err => console.error(err));
    
        return () => {
            connection.stop();
        };
    // eslint-disable-next-line
    }, [entityName]);

    const bic = !canAdd;

    if (!localStorage.getItem('householdId') && entityName !== 'households') {
        return(<NoHouseholdPage/>);
    }
    else{
        return(
            <Center mt={mt ? mt : '20'}>
                <VStack m='5' fontSize='xl' width="4xl">
                    <Flex justifyContent='space-between' w='full' maxW={w ? w : '400px'} my='6'>
                        <Heading color={!titleColor ? 'black' : titleColor}>{ title }</Heading>
                        {canAdd && <HStack>
                            {!!actionButtons && actionButtons}
                            {!buttonsInvisible && <BaseForm isEdit={false} entityName={entityName} title={formTitle} callback={updateData} form={form} foreignKeyName='householdId' foreignKey={foreignKey} bg={bg} small={true}/>} 
                        </HStack>}
                    </Flex>
                    {!!data && data.map(item => listItem({item, entityName, formTitle, updateData, form, foreignKey, toast, navigate, bic}))}
                </VStack>
            </Center>
        );
    }
};

export default BaseListPage;
