import React from 'react';
import {

} from '@chakra-ui/react'
import BaseListPage from '../../components/basePage/baseListPage';
import CostTypeForm from './costTypeForm';
import CostTypeListItem from './costTypeListItem';

const CostTypesPage = ({ householdId }) => {
    return <BaseListPage
        entityName='costtypes'
        title='Költségtípusok'
        formTitle='Költségtípus'
        listItem={CostTypeListItem}
        form={CostTypeForm}
        foreignKeyName='householdId'
        foreignKey={householdId}
        bg='#548235'/>;
}

export default CostTypesPage;
