import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import apiConfig from '../config/apiconfig';
import * as signalR from '@microsoft/signalr';

export const SelectContext = createContext();

export const SelectProvider = ({ children }) => {
    const [products, setProducts] = useState();
    const [foods, setFoods] = useState();
    const [costTypes, setCostTypes] = useState();

    const getProducts = () => {
        return new Promise((resolve, reject) => {
            if (products) {
                resolve(products);
            } else {
                axios.get(apiConfig.webApi + 'products?householdId=' + localStorage.getItem('householdId'))
                    .then((response) => {
                        setProducts(response.data.list);
                        resolve(response.data.list);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            }
        });
    };

    const getFoods = () => {
        return new Promise((resolve, reject) => {
            if (foods) {
                resolve(foods);
            } else {
                axios.get(apiConfig.webApi + 'foods?householdId=' + localStorage.getItem('householdId'))
                    .then((response) => {
                        setFoods(response.data);
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            }
        });
    };

    const getCostTypes = () => {
        return new Promise((resolve, reject) => {
            if (costTypes) {
                resolve(costTypes);
            } else {
                axios.get(apiConfig.webApi + 'costtypes?householdId=' + localStorage.getItem('householdId'))
                    .then((response) => {
                        setCostTypes(response.data.list);
                        resolve(response.data.list);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            }
        });
    };

    useEffect(() => {
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(apiConfig.signalRHub, { withCredentials: true })
            .build();
    
        connection.on("RecordModified", (notification) => {
            if (notification.entityName === "products" && notification.foreignKey === localStorage.getItem('householdId')) {
                axios.get(apiConfig.webApi + 'products?householdId=' + localStorage.getItem('householdId'))
                    .then((response) => {
                        setProducts(response.data.list);
                    })
                axios.get(apiConfig.webApi + 'foods?householdId=' + localStorage.getItem('householdId'))
                    .then((response) => {
                        setFoods(response.data);
                    })
            }
            if (notification.entityName === "compositefoods" && notification.foreignKey === localStorage.getItem('householdId')) {
                axios.get(apiConfig.webApi + 'foods?householdId=' + localStorage.getItem('householdId'))
                    .then((response) => {
                        setFoods(response.data);
                    })
            }
            if (notification.entityName === "costtypes" && notification.foreignKey === localStorage.getItem('householdId')) {
                axios.get(apiConfig.webApi + 'costtypes?householdId=' + localStorage.getItem('householdId'))
                    .then((response) => {
                        setCostTypes(response.data.list);
                    })
            }
            
        });
    
        connection.start().catch(err => console.error(err));
    
        return () => {
            connection.stop();
        };
    // eslint-disable-next-line
    }, []);

    const value = {
        getProducts,
        getFoods,
        getCostTypes
    };

    return (
        <SelectContext.Provider value={value}>
            {children}
        </SelectContext.Provider>
    );
};
