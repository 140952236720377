import React from 'react';
import {
    Flex,
    Text,
    HStack
} from '@chakra-ui/react'
import BaseForm from '../../components/basePage/baseForm';
import BaseDeleteModal from '../../components/basePage/baseDeleteModal'
import TemplateItemModal from '../templateItems/templateItemModal';

export default function MealTypeListItem({item, entityName, formTitle, updateData, form, foreignKey, bic}){
    return (
        <Flex bg='#ed7d31' color='whitesmoke' p={4} gap="2" borderRadius={10} flexDir="row" alignItems="center" justifyContent='space-between' key={item.id} w='full' maxW='400px'>
            <Text mr='4' >{ item.name }</Text>
            {!bic && <HStack>
                <TemplateItemModal id={item.id} />
                <BaseForm isEdit={true} entity={item} entityName={entityName} title={formTitle} callback={updateData} form={form} foreignKey={foreignKey}/>
                <BaseDeleteModal id={item.id} entityName={entityName} callback={updateData}/>
            </HStack>}
        </Flex>
    );
};
