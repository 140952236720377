import React from 'react';
import {
    Flex,
    Text,
    HStack,
    Popover,
    PopoverTrigger,
    PopoverContent,
    Image,
    IconButton,
} from '@chakra-ui/react'
import { ViewIcon } from '@chakra-ui/icons'
import BaseForm from '../../components/basePage/baseForm';
import BaseDeleteModal from '../../components/basePage/baseDeleteModal'
import apiConfig from '../../config/apiconfig';

export default function ProductListItem({item, entityName, formTitle, updateData, form, foreignKey, bic}){
    return (
        <Flex bg='#548235' color='whitesmoke' p={4} gap="2" borderRadius={10} flexDir="row" alignItems="center" justifyContent='space-between' key={item.id} w='full' maxW='400px'>
            <Text mr='4' >{ item.name }</Text>
            <HStack>
                ({!!item.imagePath && <Popover>
                    <PopoverTrigger>
                        <IconButton icon={<ViewIcon/>} boxSize='8' size='xl' color='whitesmoke' bg='transparent' />
                    </PopoverTrigger>
                    <PopoverContent borderRadius='10' borderColor='black'>
                        <Image src={apiConfig.imageRoute + item.imagePath} borderRadius='10'/>
                    </PopoverContent>
                </Popover>})
                {!bic && <>
                    <BaseForm isEdit={true} entity={item} entityName={entityName} title={formTitle} callback={updateData} form={form} foreignKey={foreignKey}/>
                    <BaseDeleteModal id={item.id} entityName={entityName} callback={updateData}/>
                </>}
            </HStack>
        </Flex>
    );
};
