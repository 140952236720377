import React, { useContext, useState } from 'react';
import {
    Button,
    Modal,
    ModalOverlay,
    ModalBody,
    ModalContent,
    useDisclosure,
    IconButton,
    useToast,
    Tooltip,
    FormControl,
    FormLabel,
    Input,
    NumberInput,
    InputGroup,
    NumberInputField,
    InputRightAddon,
    ModalHeader,
    ModalFooter
} from '@chakra-ui/react'
import axios from 'axios';
import apiConfig from '../../config/apiconfig';
import { FaStop } from "react-icons/fa";
import { ErrorNotifierContext } from '../../providers/errorNotifier';

export default function ConfirmComplete({id, callback}) {
    const [name, setName] = useState();
    const [amount, setAmount] = useState(0);

    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = React.useRef(null);
    const finalRef = React.useRef(null);
    const cancelRef = React.useRef()

    const { notify } = useContext(ErrorNotifierContext);

    const complete = (event) => {
        event.preventDefault();
        if (name && amount && amount > 0) {
            axios.post(apiConfig.webApi + 'shoppings/complete/' + id, { name: name, amount: amount })
                .then(() => {
                    onClose();
                    callback();
                    toast({ title: 'Sikeres lezárás', status: 'success', duration: 2000, isClosable: true});
                })
                .catch((error) => {
                    notify(error, 'Sikertelen lezárás');
                });
        }
    }

    const toast = useToast();
  
    return (
      <>
        <Tooltip label="Lezárás">
            <IconButton icon={<FaStop/>} onClick={onOpen} boxSize='8' size='xl' color='whitesmoke' bg='transparent'/>
        </Tooltip>
  
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={isOpen}
          onClose={onClose}
        >
            <ModalOverlay />
            <ModalContent width="90%">
                <ModalHeader fontSize='lg' fontWeight='bold'>Lezárás megerősítése</ModalHeader>
                <form onSubmit={complete}>
                    <ModalBody>
                        Biztosan le szeretnéd zárni a bevásárlást? Csak akkor zárd le, ha tényleg befejezted a vásárlást.
                        <FormControl>
                            <FormLabel>Költség neve</FormLabel>
                            <Input type="text" onChange={(event) => setName(event.target.value)} mb='4' autoComplete='off'/>
                            </FormControl>
                        <FormControl>
                            <FormLabel>Költség összege</FormLabel>
                            <NumberInput precision={0} min={0} mb='4'>
                                <InputGroup>
                                    <NumberInputField textAlign='end' onChange={(event) => setAmount(event.target?.value)}/>
                                    <InputRightAddon>Ft</InputRightAddon>
                                </InputGroup>
                            </NumberInput>
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            Mégse
                        </Button>
                        <Button colorScheme='green' type="submit" ml={3}>
                            Lezárás
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
      </>
    )
}
