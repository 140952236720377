import React from 'react';
import {
    FormControl,
    FormLabel,
    VStack,
    Input
} from '@chakra-ui/react'

export default function CostTypeForm({title, data, setData, foreignKey}){
    const setName = (name) => {
        if (!data) {
            data = {};
        }
        if (!data.householdId) {
            data.householdId = foreignKey;
        }
        data.name = name;
        setData(data);
    };

    return (
        <VStack w='full'>
            <FormControl>
                <FormLabel>{title} neve</FormLabel>
                <Input type="text" defaultValue={!!data ? data.name : ''} onChange={(event) => setName(event.target.value)} mb='4' autoComplete='off'/>
            </FormControl>
        </VStack>
    );
};
