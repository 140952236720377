import React, { useState, useContext, useEffect } from 'react';
import {
    VStack,
    Center,
    Heading,
    Flex,
    HStack,
    Text,
    Button,
    Input
} from '@chakra-ui/react'
import axios from 'axios';
import apiConfig from '../../config/apiconfig';
import { useNavigate } from 'react-router-dom';
import { ErrorNotifierContext } from '../../providers/errorNotifier';

const now = new Date();
const previousMonth = new Date(now.setMonth(now.getMonth() - 1));

const AnnualSummaryPage = () => {
    const [data, setData] = useState();
    const [fromYear, setFromYear] = useState(previousMonth.toISOString().slice(0, 4));
    const [fromMonth, setFromMonth] = useState('01');
    const [toYear, setToYear] = useState(previousMonth.toISOString().slice(0, 4));
    const [toMonth, setToMonth] = useState(previousMonth.toISOString().slice(5, 7));

    const navigate = useNavigate();
    const { notify } = useContext(ErrorNotifierContext);
    const handleClick = (url) => {
        navigate(url);
    }

    const doFetch = () => {
        if (!!localStorage.getItem('householdId') && fromYear && fromMonth && toYear && toMonth) {
            axios.get(apiConfig.webApi + 'costs/annualsummary?householdId=' + localStorage.getItem('householdId') + '&fromYear=' + fromYear + '&fromMonth=' + fromMonth + '&toYear=' + toYear + '&toMonth=' + toMonth)
                .then((response) => {
                    setData(response.data);
                })
                .catch((error) => {
                    notify(error, 'Sikertelen lekérdezés');
                });
        }
    };

    const fromMonthChange = (date) => {
        setFromYear(date.slice(0,4));
        setFromMonth(date.slice(5,7));
    };

    const toMonthChange = (date) => {
        setToYear(date.slice(0,4));
        setToMonth(date.slice(5,7));
    };

    useEffect(() => {
        doFetch();
      //eslint-disable-next-line
      }, []);

    return(
        <Center mt='20'>
            <VStack m='5' fontSize='xl' w={!!data ? '6xl' : '4xl'}>
                <Flex justifyContent='space-between' w='full' my='6'>
                    <Heading color='black'>Több havi kimutatások</Heading>
                    <HStack>
                        <Button onClick={() => handleClick("/monthlysummary")} alignSelf={"center"} fontWeight={'bold'} color='whitesmoke' bg='#548235'>Havi</Button>
                    </HStack>
                </Flex>
                <Flex w='full' className='desktop-filter'>
                    <Input type="month" defaultValue={fromYear + '-' + fromMonth} onChange={(event) => fromMonthChange(event.target.value)} autoComplete='off' maxW='200' mr='4'/>
                    <Text mr='4'>-</Text>
                    <Input type="month" defaultValue={toYear + '-' + toMonth} onChange={(event) => toMonthChange(event.target.value)} autoComplete='off' maxW='200' mr='4'/>
                    <Button onClick={() => doFetch()} alignSelf={"center"} fontWeight={'bold'} color='whitesmoke' bg='#548235' p={4}>Szűrés</Button>
                </Flex>
                <Flex w='full' className='mobile-filter' direction='column'>
                    <Input type="month" defaultValue={fromYear + '-' + fromMonth} onChange={(event) => fromMonthChange(event.target.value)} autoComplete='off' mr='4'/>
                    <Text alignSelf='center'>-</Text>
                    <Input type="month" defaultValue={toYear + '-' + toMonth} onChange={(event) => toMonthChange(event.target.value)} autoComplete='off' mr='4'/>
                    <Button onClick={() => doFetch()} alignSelf='end' fontWeight={'bold'} color='whitesmoke' bg='#548235' p={4} mt='4'>Szűrés</Button>
                </Flex>
                {!!data && data.sum > 0 && <>
                <Flex direction='row' wrap='wrap' justifyContent='center' w='full'>
                    <VStack m='4' w='full' className='full-width-summary'>
                        <Text>Összesítő:</Text>
                        <Flex bg='#548235' color='whitesmoke' p={4} gap="2" borderRadius={10} flexDir="row" alignItems="center" justifyContent='space-between' w='full' maxW='550px'>
                            <Text mr='4' fontSize='x-large'>Összesen</Text>
                            <Text mr='4' fontSize='x-large'>{ data.sum } Ft</Text>
                        </Flex>
                        {data.categories.map(item => <>
                            <Flex bg='#548235' color='whitesmoke' p={4} gap="2" borderRadius={10} flexDir="row" alignItems="center" justifyContent='space-between' key={item.id} w='full' maxW='550px'>
                                <Text mr='4' fontSize='large'>{ item.name }</Text>
                                <Text mr='4' fontSize='large'>{ item.amount } Ft</Text>
                            </Flex>
                        </>)}
                    </VStack>
                    <VStack m='4' w='full' className='full-width-summary'>
                        <Text>Havi átlag:</Text>
                        <Flex bg='#548235' color='whitesmoke' p={4} gap="2" borderRadius={10} flexDir="row" alignItems="center" justifyContent='space-between' w='full' maxW='550px'>
                            <Text mr='4' fontSize='x-large'>Összesen</Text>
                            <Text mr='4' fontSize='x-large'>{ data.averageSum } Ft</Text>
                        </Flex>
                        {data.averageCategories.map(item => <>
                            <Flex bg='#548235' color='whitesmoke' p={4} gap="2" borderRadius={10} flexDir="row" alignItems="center" justifyContent='space-between' key={item.id} w='full' maxW='550px'>
                                <Text mr='4' fontSize='large'>{ item.name }</Text>
                                <Text mr='4' fontSize='large'>{ item.amount } Ft</Text>
                            </Flex>
                        </>)}
                    </VStack>
                    <VStack m='4' w='full' className='full-width-summary'>
                        <Text>Több havi költségek:</Text>
                        {data.annualData.map(item => <>
                            <Flex bg='#548235' color='whitesmoke' p={4} gap="2" borderRadius={10} flexDir="row" alignItems="center" justifyContent='space-between' key={item.id} w='full' maxW='550px'>
                                <Flex w='full' justifyContent='space-between'>
                                    <Text mr='4' fontSize='medium' whiteSpace="nowrap" wordBreak="keep-all">{ item.name }</Text>
                                    <Text mr='4' fontSize='medium' whiteSpace="nowrap" wordBreak="keep-all">{ item.amount } Ft</Text>
                                </Flex>
                                <Flex wrap='wrap' flexDir="column">
                                    <Text fontSize='medium' whiteSpace="nowrap" wordBreak="keep-all">{ item.costTypeName }</Text>
                                    <Text fontSize='medium' whiteSpace="nowrap" wordBreak="keep-all">{ new Date(item.created).toLocaleDateString('hu-HU') }</Text>
                                </Flex>
                            </Flex>
                        </>)}
                        <Text>Havi költségek részletezése:</Text>
                        {data.data.map(item => <>
                            <Flex bg='#548235' color='whitesmoke' p={4} gap="2" borderRadius={10} flexDir="row" alignItems="center" justifyContent='space-between' key={item.id} w='full' maxW='550px'>
                                <Flex w='full' justifyContent='space-between'>
                                    <Text mr='4' fontSize='medium' whiteSpace="nowrap" wordBreak="keep-all">{ item.name }</Text>
                                    <Text mr='4' fontSize='medium' whiteSpace="nowrap" wordBreak="keep-all">{ item.amount } Ft</Text>
                                </Flex>
                                <Flex wrap='wrap' flexDir="column">
                                    <Text fontSize='medium' whiteSpace="nowrap" wordBreak="keep-all">{ item.costTypeName }</Text>
                                    <Text fontSize='medium' whiteSpace="nowrap" wordBreak="keep-all">{ new Date(item.created).toLocaleDateString('hu-HU') }</Text>
                                </Flex>
                            </Flex>
                        </>)}
                    </VStack>
                </Flex>
                </>}
                {!!data && data.sum <= 0 && <Text>Nincs adat erre az időszakra</Text>}
            </VStack>
        </Center>
    );
};

export default AnnualSummaryPage;
