import React, { useContext } from 'react';
import {
    Flex,
    Text,
    HStack,
    IconButton,
    Popover,
    PopoverTrigger,
    PopoverContent,
    Image,
    Tooltip
} from '@chakra-ui/react'
import BaseDeleteModal from '../../components/basePage/baseDeleteModal'
import BaseForm from '../../components/basePage/baseForm';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import apiConfig from '../../config/apiconfig';
import { ViewIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { ErrorNotifierContext } from '../../providers/errorNotifier';

export default function ShoppingItemListItem({item, entityName, formTitle, updateData, form, foreignKey, toast, bic}){
    const { notify } = useContext(ErrorNotifierContext);
    
    const toggleShoppingItem = () => {
        axios.put(apiConfig.webApi + 'shoppingitems/' + item.id + '/toggle')
            .then(() => {
                updateData();
                toast({ title: 'Sikeres mentés', status: 'success', duration: 2000, isClosable: true});
            })
            .catch(error => {
                notify(error, 'Sikertelen mentés');
            });
    };

    return (
        <Flex bg='#ed7d31' color='whitesmoke' p={4} gap="2" borderRadius={10} flexDir="row" alignItems="center" justifyContent='space-between' key={item.id} w='full' maxW='400px'>
            <HStack>
                <Text>{ item.productName }</Text>
                ({!!item.productImagePath && <Popover>
                    <PopoverTrigger>
                        <IconButton icon={<ViewIcon/>} boxSize='8' size='xl' color='whitesmoke' bg='transparent' />
                    </PopoverTrigger>
                    <PopoverContent borderRadius='10' borderColor='black'>
                        <Image src={apiConfig.imageRoute + item.productImagePath} borderRadius='10'/>
                    </PopoverContent>
                </Popover>})
            </HStack>
            <HStack>
                <Text mr='4' >{ item.quantity } db</Text>
                {!bic && <BaseForm isEdit={true} entity={item} entityName={entityName} title={formTitle} callback={updateData} form={form} foreignKey={foreignKey}/>}
                {!bic && <BaseDeleteModal id={item.id} entityName={entityName} callback={updateData}/>}
                {!bic &&<Tooltip label={item.bought ? "Mégsem vettem meg" : "Megvettem"}>
                    <IconButton icon={item.bought ? <CloseIcon/> : <CheckIcon/>} onClick={toggleShoppingItem} boxSize='8' size='xl' color='whitesmoke' bg='transparent'/>
                </Tooltip>}
            </HStack>
        </Flex>
    );
};
