import React, { useState, useContext } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    useDisclosure,
    ModalHeader,
    ModalCloseButton,
    IconButton,
    useToast,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Icon
} from '@chakra-ui/react'
import axios from 'axios';
import apiConfig from '../../config/apiconfig';
import { ErrorNotifierContext } from '../../providers/errorNotifier';
import FoodRecommenderStockItemForm from './foodRecommenderStockItemForm';
import FoodRecommenderRecipeForm from './foodRecommenderRecipeForm';
import MindmegetteIngredients from '../mindmegette/mindmegetteIngredients';

export default function FoodRecommenderModal() {
    const { isOpen, onOpen } = useDisclosure();

    const toast = useToast();
    const { notify } = useContext(ErrorNotifierContext);

    const [tabIndex, setTabIndex] = useState(0);
    const [title, setTitle] = useState();
    const [recipe, setRecipe] = useState();
    const [imageUrl, setImageUrl] = useState();
    const [stockItems, setStockItems] = useState();
    const [withPicture, setWithPicture] = useState(false);
    const [id, setId] = useState();
    const [ingredients, setIngredients] = useState([]);

    const initialRef = React.useRef(null);
    const finalRef = React.useRef(null);

    const submitStockItems = (event, selectedStockItems, withPicture) => {
        event.preventDefault();
        if (selectedStockItems.length < 2) {
            toast({ title: 'Legalább 2 hozzávalót jelölj ki!', status: 'error', duration: 2000, isClosable: true});
            return;
        }
        setStockItems(selectedStockItems);
        setWithPicture(withPicture);
        const networkCall = new Promise((resolve, reject) => {
            axios.post(apiConfig.webApi + 'compositefoods/foodrecommender/recommend', { selectedStockItems: selectedStockItems, withPicture: withPicture })
                .then((response) => {
                    setTitle(response.data.title);
                    setRecipe(response.data.recipe);
                    setIngredients(response.data.ingredients);
                    setImageUrl(response.data.imageUrl);
                    setTabIndex(1);
                    resolve();
                })
                .catch((error) => {
                    notify(error, 'Sikertelen lekérdezés');
                    reject();
                });
        });

        toast.promise(networkCall, {
            success: { title: 'Sikeres generálás' },
            error: { title: 'Hiba történt' },
            loading: { title: 'Generálás folyamatban' },
        });
    };

    const submitGenerateNext = () => {
        const networkCall = new Promise((resolve, reject) => {
            axios.post(apiConfig.webApi + 'compositefoods/foodrecommender/recommend', { selectedStockItems: stockItems, withPicture: withPicture })
                .then((response) => {
                    setTitle(response.data.title);
                    setRecipe(response.data.recipe);
                    setIngredients(response.data.ingredients);
                    setImageUrl(response.data.imageUrl);
                    setTabIndex(1);
                    resolve();
                })
                .catch((error) => {
                    notify(error, 'Sikertelen lekérdezés');
                    reject();
                });
        });

        toast.promise(networkCall, {
            success: { title: 'Sikeres generálás' },
            error: { title: 'Hiba történt' },
            loading: { title: 'Generálás folyamatban' },
        });
    };

    const submitRecipe = (event) => {
        event.preventDefault();
        const networkCall = new Promise((resolve, reject) => {
            axios.post(apiConfig.webApi + 'compositefoods/mindmegette/add', { householdId: localStorage.getItem('householdId'), name: title, recipe: recipe, imagePath: imageUrl })
                .then((response) => {
                    setId(response.data.id);
                    setTabIndex(2);
                    resolve();
                })
                .catch((error) => {
                    notify(error, 'Sikertelen létrehozás');
                    reject();
                });
        });

        toast.promise(networkCall, {
            success: { title: 'Sikeres létrehozás' },
            error: { title: 'Hiba történt' },
            loading: { title: 'Létrehozás folyamatban' },
        });
    };

    const customOnClose = () => {
        window.location.reload();
    };

    return (
        <>
            <IconButton borderRadius="0" icon={<Icon viewBox='0 0 256 260' boxSize="40px" padding="0" background="white">
                <path d="M239.184 106.203a64.72 64.72 0 0 0-5.576-53.103C219.452 28.459 191 15.784 163.213 21.74A65.586 65.586 0 0 0 52.096 45.22a64.72 64.72 0 0 0-43.23 31.36c-14.31 24.602-11.061 55.634 8.033 76.74a64.67 64.67 0 0 0 5.525 53.102c14.174 24.65 42.644 37.324 70.446 31.36a64.72 64.72 0 0 0 48.754 21.744c28.481.025 53.714-18.361 62.414-45.481a64.77 64.77 0 0 0 43.229-31.36c14.137-24.558 10.875-55.423-8.083-76.483m-97.56 136.338a48.4 48.4 0 0 1-31.105-11.255l1.535-.87l51.67-29.825a8.6 8.6 0 0 0 4.247-7.367v-72.85l21.845 12.636c.218.111.37.32.409.563v60.367c-.056 26.818-21.783 48.545-48.601 48.601M37.158 197.93a48.35 48.35 0 0 1-5.781-32.589l1.534.921l51.722 29.826a8.34 8.34 0 0 0 8.441 0l63.181-36.425v25.221a.87.87 0 0 1-.358.665l-52.335 30.184c-23.257 13.398-52.97 5.431-66.404-17.803M23.549 85.38a48.5 48.5 0 0 1 25.58-21.333v61.39a8.29 8.29 0 0 0 4.195 7.316l62.874 36.272l-21.845 12.636a.82.82 0 0 1-.767 0L41.353 151.53c-23.211-13.454-31.171-43.144-17.804-66.405zm179.466 41.695l-63.08-36.63L161.73 77.86a.82.82 0 0 1 .768 0l52.233 30.184a48.6 48.6 0 0 1-7.316 87.635v-61.391a8.54 8.54 0 0 0-4.4-7.213m21.742-32.69l-1.535-.922l-51.619-30.081a8.39 8.39 0 0 0-8.492 0L99.98 99.808V74.587a.72.72 0 0 1 .307-.665l52.233-30.133a48.652 48.652 0 0 1 72.236 50.391zM88.061 139.097l-21.845-12.585a.87.87 0 0 1-.41-.614V65.685a48.652 48.652 0 0 1 79.757-37.346l-1.535.87l-51.67 29.825a8.6 8.6 0 0 0-4.246 7.367zm11.868-25.58L128.067 97.3l28.188 16.218v32.434l-28.086 16.218l-28.188-16.218z"></path>
            </Icon>} onClick={onOpen}/>
            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={customOnClose}
            >
                <ModalOverlay />
                <ModalContent width="90%">
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <Tabs colorScheme='green' index={tabIndex}>
                        <TabList>
                            <Tab isDisabled={tabIndex !== 0}>Készlet</Tab>
                            <Tab isDisabled={tabIndex !== 1}>Recept</Tab>
                            <Tab isDisabled={tabIndex !== 2}>Hozzávalók</Tab>
                        </TabList>

                        <TabPanels>
                            <TabPanel p='0'>
                                <FoodRecommenderStockItemForm callback={submitStockItems} customOnClose={customOnClose} />
                            </TabPanel>
                            <TabPanel p='0'>
                                <FoodRecommenderRecipeForm customOnClose={customOnClose} title={title} setTitle={setTitle} recipe={recipe} setRecipe={setRecipe} onSubmit={submitRecipe} generateNext={submitGenerateNext} imageUrl={imageUrl}/>
                            </TabPanel>
                            <TabPanel p='0'>
                                <MindmegetteIngredients rawIngredients={ingredients} compositeFoodId={id} />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </ModalContent>
            </Modal>
        </>
    )
}
