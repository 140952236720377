import React, { useState, useContext, useEffect } from 'react';
import {
    VStack,
    Center,
    Heading,
    Flex,
    HStack,
    Text,
    Button,
    Input
} from '@chakra-ui/react'
import axios from 'axios';
import apiConfig from '../../config/apiconfig';
import { useNavigate } from 'react-router-dom';
import { ErrorNotifierContext } from '../../providers/errorNotifier';

const MonthlySummaryPage = () => {
    const [data, setData] = useState();
    const [year, setYear] = useState(new Date().toISOString().slice(0, 4));
    const [month, setMonth] = useState(new Date().toISOString().slice(5, 7));

    const navigate = useNavigate();
    const { notify } = useContext(ErrorNotifierContext);
    const handleClick = (url) => {
        navigate(url);
    }

    const doFetch = () => {
        if (!!localStorage.getItem('householdId') &&  year && month) {
            axios.get(apiConfig.webApi + 'costs/monthlysummary?householdId=' + localStorage.getItem('householdId') + '&year=' + year + '&month=' + month)
                .then((response) => {
                    setData(response.data);
                })
                .catch((error) => {
                    notify(error, 'Sikertelen lekérdezés');
                });
        }
    };

    useEffect(() => {
        doFetch();
      //eslint-disable-next-line
      }, []);

    const monthChange = (date) => {
        setYear(date.slice(0,4));
        setMonth(date.slice(5,7));
    };

    return(
        <Center mt='20'>
            <VStack m='5' fontSize='xl' width="4xl">
                <Flex justifyContent='space-between' w='full' my='6'>
                    <Heading color='black'>Havi kimutatások</Heading>
                    <HStack>
                        <Button onClick={() => handleClick("/annualsummary")} alignSelf={"center"} fontWeight={'bold'} color='whitesmoke' bg='#548235'>Több havi</Button>
                    </HStack>
                </Flex>
                <Flex w='full' >
                    <Input type="month" defaultValue={year + '-' + month} onChange={(event) => monthChange(event.target.value)} autoComplete='off' maxW='200' mr='4'/>
                    <Button onClick={() => doFetch()} alignSelf={"center"} fontWeight={'bold'} color='whitesmoke' bg='#548235' p={4}>Szűrés</Button>
                </Flex>
                {!!data && data.sum > 0 && <>
                <Flex direction='row' wrap='wrap' justifyContent='center' w='full'>
                    <VStack m='4' w='full' className='full-width-summary'>
                        <Text>Összesítő:</Text>
                        <Flex bg='#548235' color='whitesmoke' p={4} gap="2" borderRadius={10} flexDir="row" alignItems="center" justifyContent='space-between' w='full' maxW='550px'>
                            <Text mr='4' fontSize='x-large'>Összesen</Text>
                            <Text mr='4' fontSize='x-large'>{ data.sum } Ft</Text>
                        </Flex>
                        {data.categories.map(item => <>
                            <Flex bg='#548235' color='whitesmoke' p={4} gap="2" borderRadius={10} flexDir="row" alignItems="center" justifyContent='space-between' key={item.id} w='full' maxW='550px'>
                                <Text mr='4' fontSize='large'>{ item.name }</Text>
                                <Text mr='4' fontSize='large'>{ item.amount } Ft</Text>
                            </Flex>
                        </>)}
                    </VStack>
                    <VStack m='4' w='full' className='full-width-summary'>
                        <Text>Költségek részletezése:</Text>
                        {data.data.map(item => <>
                            <Flex bg='#548235' color='whitesmoke' p={4} gap="2" borderRadius={10} flexDir="row" alignItems="center" justifyContent='space-between' key={item.id} w='full' maxW='550px'>
                                <Flex w='full' justifyContent='space-between'>
                                    <Text mr='4' fontSize='medium' whiteSpace="nowrap" wordBreak="keep-all">{ item.name }</Text>
                                    <Text mr='2' fontSize='medium' whiteSpace="nowrap" wordBreak="keep-all">{ item.amount } Ft</Text>
                                </Flex>
                                <Flex wrap='wrap' flexDir="column">
                                    <Text fontSize='medium' whiteSpace="nowrap" wordBreak="keep-all">{ item.costTypeName }</Text>
                                    <Text fontSize='medium' whiteSpace="nowrap" wordBreak="keep-all">{ new Date(item.created).toLocaleDateString('hu-HU') }</Text>
                                </Flex>
                            </Flex>
                        </>)}
                    </VStack>
                </Flex>
                </>}
                {!!data && data.sum <= 0 && <Text>Nincs adat erre az időszakra</Text>}
            </VStack>
        </Center>
    );
};

export default MonthlySummaryPage;
