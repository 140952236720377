import React, { useEffect, useState } from 'react';
import {
    Center,
    ChakraProvider,
    Image
} from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import BaseListPage from './components/basePage/baseListPage';
import Navbar from './components/navigation/navbar';
import ShoppingListItem from './pages/shoppings/shoppingListItem';
import ShoppingForm from './pages/shoppings/shoppingForm';
import MealTypesListPage from './pages/mealTypes/mealTypesListPage';
import CompositeFoodListItem from './pages/compositeFoods/compositeFoodListItem';
import CompositeFoodForm from './pages/compositeFoods/compositeFoodForm';
import Login from './components/login/login';
import axios from 'axios';
import apiConfig from './config/apiconfig';
import ShoppingList from './pages/shoppingItems/shoppingList';
import { SelectProvider } from './providers/selectProvider';
import Calendar from './pages/calendar/calendar';
import StockItemsPage from './pages/stockItems/stockItemsPage';
import ProductsPage from './pages/products/productsPage';
import MindmegetteModal from './components/mindmegette/mindmegetteModal';
import { ErrorNotifier } from './providers/errorNotifier';
import CostsPage from './pages/costs/costsPage';
import CostTypesPage from './pages/costTypes/costTypesPage';
import MonthlySummaryPage from './pages/costSummaries/monthlySummary';
import AnnualSummaryPage from './pages/costSummaries/annualSummary';
import PeriodicEventPage from './pages/periodicEvents/periodicEventsPage';
import HouseholdsPage from './pages/households/householdsPage';

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState();
    const [householdId, setHouseholdId] = useState();

    var refreshToken = localStorage.getItem("refreshToken");
    var accessToken = localStorage.getItem("accessToken");
    if (!!accessToken) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
        axios.get(apiConfig.baseUrl + 'manage/info')
            .then((response) => {
                setTimeout(() => {
                    setIsLoggedIn(true);
                }, 500);
            })
            .catch((error) => {
                axios.post(apiConfig.baseUrl + 'refresh', { refreshToken: refreshToken })
                    .then((response) => {
                        localStorage.setItem("accessToken", response.data.accessToken);
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.accessToken;
                        setTimeout(() => {
                            setIsLoggedIn(true);
                        }, 500);
                    })
                    .catch((error) => {
                        // nincs bejelentkezve
                        setTimeout(() => {
                            setIsLoggedIn(false);
                        }, 500);
                    });
            });
    }
    else {
        if (isLoggedIn !== false) {
            setTimeout(() => {
                setIsLoggedIn(false);
            }, 500);
        }
    }

    useEffect(() => {
        setHouseholdId(localStorage.getItem('householdId'));
    }, [isLoggedIn]);

    if (isLoggedIn === undefined) {
        return (<ChakraProvider>
            <Center>
                <Image src='loading.gif' />
            </Center>
        </ChakraProvider>);
    }

    if (!isLoggedIn) {
        return (<ChakraProvider>
            <Login />
        </ChakraProvider>);
    }

    return (
        <ChakraProvider>
            <SelectProvider>
                <ErrorNotifier>
                    <Router>
                        <Navbar />
                        <Routes>
                            <Route path='/' element={<Calendar />} />
                            <Route path='/shoppings'
                                element={<BaseListPage
                                    entityName='shoppings'
                                    title='Bevásárlások'
                                    formTitle='Bevásárlás'
                                    listItem={ShoppingListItem}
                                    form={ShoppingForm}
                                    foreignKeyName='householdId'
                                    foreignKey={householdId}
                                    bg='#ed7d31'
                                    mildReload={true}
                                    sortStrategy={(a, b) => (Date.parse(a.dateTime) < Date.parse(b.dateTime)) ? 1 : ((Date.parse(a.dateTime) > Date.parse(b.dateTime)) ? -1 : 0)} />} />
                            <Route path='/shoppinglist' element={<ShoppingList />} />
                            <Route path='/stock' element={<StockItemsPage householdId={householdId} />} />
                            <Route path='/compositefoods' element={<BaseListPage
                                entityName='compositefoods'
                                title='Kész ételek'
                                formTitle='Kész étel'
                                listItem={CompositeFoodListItem}
                                form={CompositeFoodForm}
                                foreignKeyName='householdId'
                                foreignKey={householdId}
                                bg='#548235'
                                actionButtons={[<MindmegetteModal />]}
                                sortStrategy={(a, b) => (a.name > b.name) ? 1 : ((a.name < b.name) ? -1 : 0)} />} />
                            <Route path='/products' element={<ProductsPage householdId={householdId} />} />
                            <Route path='/mealtypes' element={<MealTypesListPage householdId={householdId} />} />
                            <Route path='/households' element={<HouseholdsPage householdId={householdId}/>} />
                            <Route path='/costtypes' element={<CostTypesPage householdId={householdId}/>} />
                            <Route path='/costs' element={<CostsPage householdId={householdId}/>} />
                            <Route path='/monthlysummary' element={<MonthlySummaryPage householdId={householdId}/>} />
                            <Route path='/annualsummary' element={<AnnualSummaryPage householdId={householdId}/>} />
                            <Route path='/periodicevents' element={<PeriodicEventPage householdId={householdId}/>} />
                        </Routes>
                    </Router>
                </ErrorNotifier>
            </SelectProvider>
        </ChakraProvider>
    );
}

export default App;
