import React from 'react';
import {
    FormControl,
    FormLabel,
    VStack,
    Input,
    Checkbox,
    NumberInput,
    NumberInputField,
    InputRightAddon,
    InputGroup
} from '@chakra-ui/react';
import CostTypeSelector from '../../components/costs/costTypeSelector';

export default function CostForm({title, data, setData, foreignKey}){
    const today = new Date();
    today.setHours(14,0,0,0);

    console.log(data);

    const setName = (name) => {
        if (!data) {
            data = { created: today, annual: false };
        }
        if (!data.householdId) {
            data.householdId = foreignKey;
        }
        data.name = name;
        setData(data);
    };

    const setAmount = (amount) => {
        if (!data) {
            data = { created: today, annual: false };
        }
        if (!data.householdId) {
            data.householdId = foreignKey;
        }
        data.amount = amount;
        setData(data);
    };

    const setCreated = (created) => {
        if (!data) {
            data = { created: today, annual: false };
        }
        if (!data.householdId) {
            data.householdId = foreignKey;
        }
        created = created + 'T12:00:00';
        data.created = created;
        setData(data);
    };

    const setCostTypeId = (costTypeId) => {
        if (!data) {
            data = { created: today, annual: false };
        }
        if (!data.householdId) {
            data.householdId = foreignKey;
        }
        data.costTypeId = costTypeId;
        setData(data);
    };

    const setAnnual = (annual) => {
        if (!data) {
            data = { created: today, annual: false };
        }
        if (!data.householdId) {
            data.householdId = foreignKey;
        }
        data.annual = annual;
        setData(data);
    };

    return (
        <VStack w='full'>
            <FormControl>
                <FormLabel>{title} neve</FormLabel>
                <Input type="text" defaultValue={!!data ? data.name : ''} onChange={(event) => setName(event.target.value)} mb='4' autoComplete='off'/>
            </FormControl>
            <FormControl>
                <FormLabel>{title} összege</FormLabel>
                <NumberInput precision={0} min={0} mb='4' defaultValue={!!data ? data.amount : 0}>
                    <InputGroup>
                        <NumberInputField textAlign='end' onChange={(event) => setAmount(event.target?.value)}/>
                        <InputRightAddon>Ft</InputRightAddon>
                    </InputGroup>
                </NumberInput>
            </FormControl>
            <FormControl>
                <CostTypeSelector costTypeId={!!data ? data.costTypeId : 0} setCostTypeId={setCostTypeId} visible={true} title={title + 'típusa'}/>
            </FormControl>
            <FormControl>
                <FormLabel>Keletkezés ideje</FormLabel>
                <Input type="date" defaultValue={!!data ? new Date(data.created).toISOString().slice(0, 10) : new Date().toISOString().slice(0, 10)} onChange={(event) => setCreated(event.target.value)} mb='4' autoComplete='off'/>
            </FormControl>
            <FormControl>
                <FormLabel>Több havi elszámolásba számít</FormLabel>
                <Checkbox type="checkbox" defaultChecked={!!data ? data.annual : false} onChange={(event) => setAnnual(event.target.checked)} mb='4'/>
            </FormControl>
        </VStack>
    );
};
