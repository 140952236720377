import React, { useState, useEffect, useContext } from 'react';
import {
    HStack,
    FormLabel,
    Select
} from '@chakra-ui/react'
import 'react-select-search/style.css'
import { SelectContext } from '../../providers/selectProvider';
import { ErrorNotifierContext } from '../../providers/errorNotifier';

export default function CostTypeSelector({costTypeId, setCostTypeId, visible, title}) {
    const [costTypes, setCostTypes] = useState();
    const { getCostTypes } = useContext(SelectContext);

    const { notify } = useContext(ErrorNotifierContext);

    useEffect(() => {
        getCostTypes()
            .then(result => {
                setCostTypes(result)
                if (result.length > 0 && !costTypeId) {
                    setCostTypeId(result[0].id);
                }
            })
            .catch((error) => {
                notify(error, 'Sikertelen a lekérdezése a költségtípusoknak');
            });
    //eslint-disable-next-line
    }, []);

    return (<>
        {visible && costTypes?.length > 0 && <>
            <FormLabel>{title}</FormLabel>
            <HStack>
                <Select onChange={event => setCostTypeId(event.target.value)} defaultValue={costTypeId ? costTypeId : undefined}>
                    {!!costTypes && costTypes.length > 0 && costTypes.map(item => <option value={item.id} key={item.id}>{ item.name }</option>)}
                </Select>
            </HStack> 
        </>}
    </>
    )
}
