import React from 'react';
import BaseListPage from '../../components/basePage/baseListPage';
import PeriodicEventListItem from './periodicEventListItem';
import PeriodicEventForm from './periodicEventForm';

const PeriodicEventPage = ({ householdId }) => {
    return <BaseListPage
        entityName='periodicevents'
        title='Periodikus események'
        formTitle='Esemény'
        listItem={PeriodicEventListItem}
        form={PeriodicEventForm}
        foreignKeyName='householdId'
        foreignKey={householdId}
        w='500'
        bg='#548235'/>;
}

export default PeriodicEventPage;
