import React, { useState } from 'react';
import {
    FormControl,
    FormLabel,
    VStack,
    Input,
    Select,
    FormHelperText,
    Checkbox
} from '@chakra-ui/react'

export default function PeriodicEventForm({title, data, setData, foreignKey}) {
    const emptyDays = () => {
        return [false, false, false, false, false, false, false]
    }

    const convertToWeekDays = (data) => {
        const arr = data.split(',');
        let bArr = emptyDays();
        arr.forEach(element => {
            bArr[element-1] = true;
        });
        return bArr;
    }

    const [repeat, setRepeat] = useState(data?.repeatType ?? 0);
    const [weekDays, setWeekDays] = useState(data?.days ? convertToWeekDays(data?.days) : emptyDays());

    const setName = (name) => {
        if (!data) {
            data = {};
            data.repeatType = 0;
            data.months = '';
            data.days = '';
        }
        if (!data.householdId) {
            data.householdId = foreignKey;
        }
        data.name = name;
        setData(data);
    };

    const setRepeatType = (type) => {
        if (!data) {
            data = {};
        }
        if (!data.householdId) {
            data.householdId = foreignKey;
        }
        const number = parseInt(type);
        data.repeatType = number;
        data.months = '';
        data.days = '';
        setRepeat(number);
        setData(data);
    };

    const setMonths = (months) => {
        if (!data) {
            data = {};
        }
        if (!data.householdId) {
            data.householdId = foreignKey;
        }
        data.months = months;
        setData(data);
    };

    const setDays = (days) => {
        if (!data) {
            data = {};
        }
        if (!data.householdId) {
            data.householdId = foreignKey;
        }
        data.days = days;
        setData(data);
    };

    const getDaysFromBool = (arr) => {
        const wkArr = [];
        for (let i = 0; i < arr.length; i++) {
            if (arr[i]) {
                wkArr.push(i+1)
            }
        }
        return wkArr.join(',');
    };

    const setDay = (checked, dayIdx) => {
        if (!data) {
            data = {};
        }
        if (!data.householdId) {
            data.householdId = foreignKey;
        }
        const wkDays = weekDays;
        wkDays[dayIdx] = checked;
        setWeekDays(wkDays);
        data.days = getDaysFromBool(weekDays);
        setData(data);
    };

    return (
        <VStack w='full'>
            <FormControl mb='4'>
                <FormLabel>{title} neve</FormLabel>
                <Input type="text" defaultValue={!!data ? data.name : ''} onChange={(event) => setName(event.target.value)} autoComplete='off'/>
            </FormControl>
            <FormControl mb='4'>
                <FormLabel>Ismétlődés</FormLabel>
                <Select onChange={event => setRepeatType(event.target.value)} defaultValue={repeat}>
                    <option value={0}>Heti</option>
                    <option value={1}>Havi</option>
                </Select>
            </FormControl>
            {// eslint-disable-next-line eqeqeq
            repeat == 1 && <>
                <FormControl mb='4'>
                    <FormLabel>Hónapok</FormLabel>
                    <Input type="text" defaultValue={!!data ? data?.months : ''} onChange={(event) => setMonths(event.target.value)} autoComplete='off'/>
                    <FormHelperText>Hónapok számai vesszővel elválasztva (pl. 1,7: január,július)</FormHelperText>
                </FormControl>
                <FormControl mb='4'>
                    <FormLabel>Napok</FormLabel>
                    <Input type="text" defaultValue={!!data ? data?.days : ''} onChange={(event) => setDays(event.target.value)} autoComplete='off'/>
                    <FormHelperText>Napok számai vesszővel elválasztva (pl. 1,8,15,22)</FormHelperText>
                </FormControl>
            </>}
            {// eslint-disable-next-line eqeqeq
            repeat != 1 && <>
                <FormControl>
                    <Checkbox type="checkbox" defaultChecked={weekDays[0]} onChange={(event) => setDay(event.target.checked, 0)}>Hétfő</Checkbox>
                </FormControl>
                <FormControl>
                    <Checkbox type="checkbox" defaultChecked={weekDays[1]} onChange={(event) => setDay(event.target.checked, 1)}>Kedd</Checkbox>
                </FormControl>
                <FormControl>
                    <Checkbox type="checkbox" defaultChecked={weekDays[2]} onChange={(event) => setDay(event.target.checked, 2)}>Szerda</Checkbox>
                </FormControl>
                <FormControl>
                    <Checkbox type="checkbox" defaultChecked={weekDays[3]} onChange={(event) => setDay(event.target.checked, 3)}>Csütörtök</Checkbox>
                </FormControl>
                <FormControl>
                    <Checkbox type="checkbox" defaultChecked={weekDays[4]} onChange={(event) => setDay(event.target.checked, 4)}>Péntek</Checkbox>
                </FormControl>
                <FormControl>
                    <Checkbox type="checkbox" defaultChecked={weekDays[5]} onChange={(event) => setDay(event.target.checked, 5)}>Szombat</Checkbox>
                </FormControl>
                <FormControl>
                    <Checkbox type="checkbox" defaultChecked={weekDays[6]} onChange={(event) => setDay(event.target.checked, 6)}>Vasárnap</Checkbox>
                </FormControl>
            </>}
        </VStack>
    );
};
