import React from 'react';
import {
    Flex,
    Text,
    HStack,
    IconButton,
    Tooltip
} from '@chakra-ui/react'
import BaseForm from '../../components/basePage/baseForm';
import BaseDeleteModal from '../../components/basePage/baseDeleteModal'
import { MdFormatListBulleted } from "react-icons/md";
import { CheckIcon } from '@chakra-ui/icons';
import ConfirmQuick from './confirmQuick';
import ConfirmComplete from './confirmComplete';

export default function ShoppingListItem({item, entityName, formTitle, updateData, form, foreignKey, navigate, bic}){
    const date = new Date(item.dateTime);

    return (
        <Flex bg='#ed7d31' color='whitesmoke' p={4} gap="2" borderRadius={10} flexDir="row" alignItems="center" justifyContent='space-between' key={item.id} w='full' maxW='400px'>
            <Text mr='4' >{date.toLocaleDateString("hu-HU")}</Text>
            {!item?.isCompleted && <HStack>
                {!bic && <ConfirmQuick id={item.id} callback={updateData} />}
                {!bic && <ConfirmComplete id={item.id} callback={updateData} />}
                <Tooltip label="Bevásárló lista">
                    <IconButton icon={<MdFormatListBulleted/>} onClick={() => navigate('/shoppinglist?id=' + item.id)} boxSize='8' size='xl' color='whitesmoke' bg='transparent'/>
                </Tooltip>
                <Tooltip label="Megvásárolt tételek">
                    <IconButton icon={<CheckIcon/>} onClick={() => navigate('/shoppinglist?id=' + item.id + '&bought=true')} boxSize='8' size='xl' color='whitesmoke' bg='transparent'/>
                </Tooltip>
                {!bic && <BaseForm isEdit={true} entity={item} entityName={entityName} title={formTitle} callback={updateData} form={form} foreignKey={foreignKey}/>}
                {!bic && <BaseDeleteModal id={item.id} entityName={entityName} callback={updateData}/>}
            </HStack>}
        </Flex>
    );
};
