import React from 'react';
import {
    FormControl,
    FormLabel,
    VStack,
    Input
} from '@chakra-ui/react';
import CostTypeSelector from '../../components/costs/costTypeSelector';

export default function HouseholdForm({title, data, setData}){
    const setName = (name) => {
        if (!data) {
            data = { membersCount: 0 };
        }
        data.name = name;
        setData(data);
    };

    const setCostTypeId = (costTypeId) => {
        if (!data) {
            data = { membersCount: 0 };
        }
        data.defaultShoppingCostTypeId = costTypeId;
        setData(data);
    };

    return (
        <VStack w='full'>
            <FormControl>
                <FormLabel>{title} neve</FormLabel>
                <Input type="text" defaultValue={!!data ? data.name : ''} onChange={(event) => setName(event.target.value)} autoComplete='off'/>
            </FormControl>
            {data?.id && localStorage.getItem('householdId') === data.id &&<FormControl>
                <CostTypeSelector costTypeId={data.defaultShoppingCostTypeId} setCostTypeId={setCostTypeId} visible={true} title={'Bevásárlás alapértelmezett költségtípusa'}/>
            </FormControl>}
        </VStack>
    );
};
