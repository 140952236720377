import React from 'react';
import {
    Button
} from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";
import BaseListPage from '../../components/basePage/baseListPage';
import CostListItem from './costListItem';
import CostForm from './costForm';

const CostsPage = ({ householdId }) => {
    const navigate = useNavigate();
    const handleClick = (url) => {
        navigate(url);
    }

    const actionButtons = [
        <Button onClick={() => handleClick("/costtypes")} alignSelf={"center"} fontWeight={'bold'} color='whitesmoke' bg='#548235' key='type'>Típusok</Button>,
        <Button onClick={() => handleClick("/monthlysummary")} alignSelf={"center"} fontWeight={'bold'} color='whitesmoke' bg='#548235' key='sum'>∑</Button>
    ];

    return <BaseListPage
        entityName='costs'
        title='Költségek'
        formTitle='Költség'
        listItem={CostListItem}
        form={CostForm}
        foreignKeyName='householdId'
        foreignKey={householdId}
        bg='#548235'
        actionButtons={actionButtons}
        mildReload={true}
        sortStrategy={(a, b) => (a.created > b.created) ? -1 : ((a.created < b.created) ? 1 : 0)}
        w='550px'/>;
}

export default CostsPage;
