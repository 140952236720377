import React from 'react';
import {
    Stack,
    Link,
    Flex,
    HStack,
    Image,
    IconButton,
    useToast
} from '@chakra-ui/react';
import MobileDrawer from './mobile_drawer'
import { useNavigate } from "react-router-dom";
import HouseholdSelector from './householdSelector';
import { IoIosLogOut } from "react-icons/io";
import axios from 'axios';
import apiConfig from '../../config/apiconfig';
import { FaMoneyBill } from "react-icons/fa";

const Navbar = ({households}) => {

    const navigate = useNavigate();
    const handleClick = (url) => {
        navigate(url);
    }

    const toast = useToast();

    const handleLogout = () => {
        axios.post(apiConfig.baseUrl + 'logout', {})
            .then((response) => {
                if (response.status === 200) {
                    localStorage.removeItem("accessToken");
                    localStorage.removeItem("refreshToken");
                    localStorage.removeItem("householdId");
                    axios.defaults.headers.common['Authorization'] = '';
                    toast({ title: 'Sikeres kijelentkezés', status: 'success', duration: 2000, isClosable: true});
                    window.location.reload();
                }
            })
            .catch((error) => {
                console.log(error);
                toast({ title: 'Sikertelen kijelentkezés', status: 'error', duration: 2000, isClosable: true});
            });
    };

    return (
	<>
        <Flex className="desktop-navbar sticky-nav" backgroundColor='#548235' color='whitesmoke' height={85} padding="0.5rem calc((100vw - 1300px) / 2)">
            <HStack w='100%' justifyContent='space-between' px='10'>
                <Stack ml='10' spacing={8} direction='row' mr="10" >
                    <Image src="calendar-icon.png" h="40px" mt='0.5'/>
                    <Link onClick={() => handleClick("/")} _hover={false} alignSelf={"center"} fontWeight={'bold'}>Naptár</Link>
                    <Link onClick={() => handleClick("/shoppings")} _hover={false} alignSelf={"center"} fontWeight={'bold'}>Bevásárlások</Link>
                    <Link onClick={() => handleClick("/stock")} _hover={false} alignSelf={"center"} fontWeight={'bold'}>Készlet</Link>
                    <Link onClick={() => handleClick("/compositefoods")} _hover={false} alignSelf={"center"} fontWeight={'bold'}>Kész ételek</Link>
                    <Link onClick={() => handleClick("/products")} _hover={false} alignSelf={"center"} fontWeight={'bold'}>Termékek</Link>
                    <Link onClick={() => handleClick("/households")} _hover={false} alignSelf={"center"} fontWeight={'bold'}>Háztartásaim</Link>
                    <Link onClick={() => handleClick("/costs")} _hover={false} alignSelf={"center"} fontWeight={'bold'}>Költségeim</Link>
                    <Link onClick={() => handleClick("/periodicevents")} _hover={false} alignSelf={"center"} fontWeight={'bold'}>Események</Link>
                </Stack>
                <HStack>
                    <HouseholdSelector color='black'/>
                    <IconButton variant='outline' color='whitesmoke' icon={<IoIosLogOut/>} onClick={handleLogout}/>
                </HStack>
            </HStack>
        </Flex>
        <Flex className="mobile-navbar sticky-nav" backgroundColor='#548235' color='whitesmoke' height={85} padding="0.5rem calc((100vw - 1000px) / 2)" wrap="nowrap" justifyContent="space-between">
            <HStack  ml="2">
                <Image src="calendar-icon.png" h="40px" />
                <Link onClick={() => handleClick("/")} fontSize={24} _hover={false} alignSelf={"center"} fontWeight={'bold'}>Naptár</Link>
            </HStack>
            <HStack>
                <IconButton icon={<FaMoneyBill color={'white'}/>} onClick={() => handleClick("/costs")} fontSize="32px" backgroundColor="transparent" alignSelf="center" justifySelf="center" my="5"/>
                <MobileDrawer handleLogout={handleLogout}/>
            </HStack>
        </Flex>
	</>
    );
};

export default Navbar;
