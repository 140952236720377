import React from 'react';
import {
    Flex,
    Text,
    HStack
} from '@chakra-ui/react'
import BaseForm from '../../components/basePage/baseForm';
import BaseDeleteModal from '../../components/basePage/baseDeleteModal'
import MembersModal from '../members/membersModal';

export default function HouseholdListItem({item, entityName, formTitle, updateData, form, bic}){
    return (
        <Flex bg='#548235' color='whitesmoke' p={4} gap="2" borderRadius={10} flexDir="row" alignItems="center" justifyContent='space-between' key={item.id} w='full' maxW='400px'>
            <Text mr='4' >{item.name}</Text>
            <HStack>
                <MembersModal membersCount={item.membersCount} householdId={item.id}/>
                {!bic && <>
                    <BaseForm isEdit={true} entity={item} entityName={entityName} title={formTitle} callback={updateData} form={form} />
                    <BaseDeleteModal id={item.id} entityName={entityName} callback={updateData}/>
                </>}
            </HStack>
        </Flex>
    );
};
