import React from 'react';
import {
    Flex,
    Text,
    HStack,
} from '@chakra-ui/react'
import BaseDeleteModal from '../../components/basePage/baseDeleteModal'
import BaseForm from '../../components/basePage/baseForm';

export default function StockItemListItem({item, entityName, formTitle, updateData, form, foreignKey, bic}){
    return (
        <Flex bg='#ed7d31' color='whitesmoke' p={4} gap="2" borderRadius={10} flexDir="row" alignItems="center" justifyContent='space-between' key={item.id} w='full' maxW='500px'>
            <HStack>
                <Text mr='4' >{ item.productName }</Text>
            </HStack>
            <Text mr='4' >{ item.lastUpdated }</Text>
            <HStack>
                <Text mr='4' >{ item.quantity } db</Text>
                {!bic && <>
                    <BaseForm isEdit={true} entity={item} entityName={entityName} title={formTitle} callback={updateData} form={form} foreignKey={foreignKey}/>
                    <BaseDeleteModal id={item.id} entityName={entityName} callback={updateData}/>
                </>}
            </HStack>
        </Flex>
    );
};
